import gql from "graphql-tag";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  UserUpdatePasswordCustom,
  UserUpdatePasswordCustomVariables,
} from "./__generated__/UserUpdatePasswordCustom";
import { useUserToken } from "@igloo-be-omnipartners/hooks";

const UserUpdatePasswordMutation = gql`
  mutation UserUpdatePasswordCustom(
    $token: String!
    $oldPassword: String!
    $password: String!
  ) {
    userUpdatePasswordCustom(
      token: $token
      oldPassword: $oldPassword
      password: $password
    ) {
      message
      code
    }
  }
`;

export const useUserUpdatePassword = () => {
  const token = useUserToken();
  const [loading, setLoading] = useState<boolean | undefined>();

  const [updatePassword, mutationResult] = useMutation<
    UserUpdatePasswordCustom,
    UserUpdatePasswordCustomVariables
  >(UserUpdatePasswordMutation);

  return {
    ...mutationResult,
    error:
      mutationResult.error ||
      (mutationResult.data && mutationResult.data.userUpdatePasswordCustom),
    userUpdatePassword: async ({
      oldPassword,
      password,
    }: {
      oldPassword: string;
      password: string;
    }) => {
      try {
        setLoading(true);
        const { data } = await updatePassword({
          variables: {
            token,
            oldPassword,
            password,
          },
        });

        setLoading(false);
        return {
          error: data && data.userUpdatePasswordCustom,
        };
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    loading,
  };
};
