import {
  Wrapper,
  ILitter,
  Alert,
  LittersAddConfirm,
  PageLoading,
} from "@royalcanin-be-partner-portal/ui-kit";
import React, { useState, useEffect } from "react";
import { Layout } from "../../components/Layout";
import {
  FormLitterActions,
  IFormLitterActions,
} from "../../components/FormLitterActions";
import { CheckPermission } from "../../components/CheckPermission";
import { FormLitterPack } from "../../components/FormLitterPack";
import { FormLitterAd } from "../../components/FormLitterAd";
import { FormLitterBaseInfo } from "../../components/FormLitterBaseInfo";
import { useLitterCreate, useLitterUpdate } from "../../lib/hooks/useLitters";
import { Row, Col } from "react-styled-flexboxgrid";
import { withPage } from "../../lib/withPage";
import { IFormLitterAdInfoValues } from "../../components/FormLitterAdInputs";
import { useAmagingUploadTokenForLitter } from "../../lib/hooks/useAmagingUploadToken";
import { omit } from "lodash";
import { useLitterRoyalStart } from "../../lib/hooks/useLittersRoyalStart";
import { RouteComponentProps } from "@reach/router";
import { parseQuery } from "../../lib/LocationProvider";
import { FormattedMessage } from "react-intl";

interface ILitterAddValues
  extends Pick<
    ILitter,
    | "type"
    | "male"
    | "female"
    | "availableMale"
    | "availableFemale"
    | "visible"
    | "dateVisibleFrom"
    | "dateVisibleTo"
  > {
  breedId: string;
  dateAvailable: string;
  partnerExtid: string;
  packRange: string;
  packDeliveryExtid: string;
  createdByGUID: string;
  descriptions: string;
  dob: string;
  numberOfPack: number;
  royalStartId?: string;
  royalStartSyncAvailable: boolean;
  genderUnspecifiedCount?: number | null;
}

type IStep =
  | "baseInfo"
  | "actions"
  | "pack"
  | "litter"
  | "confirmPack"
  | "confirmAd";

type IValues = Partial<ILitterAddValues> & Partial<IFormLitterAdInfoValues>;

const LittersAdd = ({ royalStartId }: { royalStartId?: string }) => {
  const [step, setStep] = useState<IStep>("baseInfo");
  const [values, setValues] = useState<IValues>();
  const [actions, setActions] = useState<IFormLitterActions>();
  const { litterCreate } = useLitterCreate();
  const { litterUpdate } = useLitterUpdate();
  const [error, setError] = useState<{ message: string }>();
  const { getAmagingUploadToken } = useAmagingUploadTokenForLitter();
  const { litter: litterRS, loading: loadingLitterRS } =
    useLitterRoyalStart(royalStartId);

  useEffect(() => {
    if (litterRS) {
      setValues({
        ...omit(litterRS, ["__typename", "id", "breedCode"]),
        availableMale: litterRS.availableMale || 0,
        availableFemale: litterRS.availableFemale || 0,
        royalStartId,
        genderUnspecifiedCount: litterRS.genderUnspecifiedCount || 0,
        photos: (litterRS.photos || []).map(({ url }) => ({
          preview: url,
        })),
      });
      if (litterRS.breedId) {
        setStep("actions");
      }
    }
  }, [litterRS]);

  if (loadingLitterRS) {
    return <PageLoading />;
  }

  const littersAddHeaderProps: NonNullable<
    React.ComponentProps<typeof FormLitterBaseInfo>["littersAddHeaderProps"]
  > = {};
  if (!!royalStartId) {
    littersAddHeaderProps.warningMessage = (
      <FormattedMessage
        id="litters.add.message.data_come_from_royalstart"
        defaultMessage="Les données pré-complétées proviennent de Royal Start"
      />
    );
  }

  return (
    <Wrapper>
      {!!error && <Alert type="error">{error.message}</Alert>}

      {step === "baseInfo" && (
        <FormLitterBaseInfo
          initialValues={values}
          onSubmit={(v) => {
            setValues({
              ...v,
              female: v.female || 0,
              male: v.male || 0,
              dob: v.dob || undefined,
              breedId: v.breedId || undefined,
            });
            setStep("actions");
          }}
          littersAddHeaderProps={littersAddHeaderProps}
          maleFemaledisabled={!!royalStartId}
        />
      )}

      {step === "actions" && (
        <FormLitterActions
          onSubmit={(v) => {
            setActions(v);
            if (!v.litter) {
              setStep("pack");
            } else {
              setStep("litter");
            }
          }}
          onCancel={() => {
            setStep("baseInfo");
          }}
          initialValues={actions}
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}

      {step === "pack" && (
        <FormLitterPack
          initialValues={{
            ...values,
            male: values?.male || 0,
            female: values?.female || 0,
            genderUnspecifiedCount: values?.genderUnspecifiedCount || 0,
          }}
          onSubmit={async (packValues) => {
            const data = {
              ...values,
              ...packValues,
            };
            const { error: errorLitterCreate } = await litterCreate(
              data as any,
            );
            if (errorLitterCreate) {
              setError(errorLitterCreate);
            } else {
              setValues(data);
              setStep("confirmPack");
            }
          }}
          onCancel={() => {
            setStep("actions");
          }}
          readonlyTotal
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}

      {step === "litter" && (
        <FormLitterAd
          pack={actions?.pack}
          onCancel={() => {
            setStep("actions");
          }}
          onSubmit={async (adValues) => {
            const data: IValues = {
              ...values,
              ...adValues,
            };
            const { error: errorLitterCreate, litter } = await litterCreate(
              omit(data, ["photos"]) as any,
            );
            if (errorLitterCreate) {
              setError(errorLitterCreate);
            } else {
              setValues(data);
              const { photos } = data;
              if (litter && photos && photos.length) {
                const photosData = (
                  await Promise.all(
                    photos.map(async (photo, i) => {
                      if (!photo.file) {
                        return {
                          id: photo.id as number,
                          url: photo.preview as string,
                        };
                      }

                      const { token: amagingUploadToken } =
                        await getAmagingUploadToken(litter.id, i);
                      if (!amagingUploadToken) {
                        return;
                      }

                      await fetch(amagingUploadToken.url, {
                        method: "post",
                        body: photo.file,
                        headers: {
                          Accept: "application/json",
                          Authorization: "Bearer " + amagingUploadToken.token,
                          "Content-Lenght": photo.file.size.toString(),
                          "Content-Type": photo.file.type,
                        },
                      });

                      return {
                        id: -1,
                        url: amagingUploadToken.urlPreview,
                      };
                    }),
                  )
                ).filter(Boolean) as {
                  id: number;
                  url: string;
                }[];

                await litterUpdate(litter.id, {
                  photos: photosData.map((photo) => ({
                    ...photo,
                    id: (photo.id && photo.id.toString()) || "-1",
                  })),
                });
              }
              setStep("confirmAd");
            }
          }}
          initialValues={values}
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}

      {step === "confirmPack" && <ConfirmPack />}
      {step === "confirmAd" && (
        <ConfirmAd values={values as ILitterAddValues} />
      )}
    </Wrapper>
  );
};

const LittersAddPage = ({ location }: RouteComponentProps) => {
  const { rsId }: { rsId?: string } = parseQuery(location);
  return (
    <Layout>
      <CheckPermission permissions={"litters"} redirect>
        <LittersAdd royalStartId={rsId} />
      </CheckPermission>
    </Layout>
  );
};

export default withPage(LittersAddPage);

const ConfirmPack = (props: React.ComponentProps<typeof LittersAddConfirm>) => (
  <Row center="xs">
    <Col xs={12} sm={12} md={10} lg={8}>
      <LittersAddConfirm {...props} />
    </Col>
  </Row>
);
const ConfirmAd = ({
  values,
  ...props
}: React.ComponentProps<typeof LittersAddConfirm> & {
  values?: ILitterAddValues;
}) => (
  <Row center="xs">
    <Col xs={12} sm={12} md={10} lg={8}>
      <LittersAddConfirm
        {...props}
        adDateStart={values?.dateVisibleFrom || undefined}
        adDateEnd={values?.dateVisibleTo || undefined}
      />
    </Col>
  </Row>
);
