import React from "react";
import { useUserRole } from "../lib/hooks/useUserRole";
import { PageLoading } from "@royalcanin-be-partner-portal/ui-kit";
import { Redirect } from "@reach/router";

export const CheckPartnerType = ({
  types,
  fallbackRedirectTo = "/information",
  children,
}: {
  types: string[];
  fallbackRedirectTo?: string;
  children?: React.ReactNode;
}) => {
  const { data: role, loading: userRoleLoading } = useUserRole();
  const currentPartnerType = role?.partnerRelation.partner.type;

  if (userRoleLoading || !currentPartnerType) {
    return <PageLoading />;
  }

  if (types.indexOf(currentPartnerType) < 0) {
    return <Redirect to={fallbackRedirectTo} />;
  }

  return <>{children}</>;
};

export const checkHasPermission = (
  sectionEnabled: string[],
  permissions: string | string[],
) => {
  if (typeof permissions === "string") {
    permissions = [permissions];
  }

  let hasPermission = false;
  for (const permission of permissions) {
    if (sectionEnabled.indexOf(permission) >= 0) {
      hasPermission = true;
      break;
    }
  }
  return hasPermission;
};

export const CheckPermission = ({
  permissions,
  children,
  renderLoading = () => <PageLoading />,
  redirect,
}: {
  permissions: string | string[];
  children?: React.ReactNode;
  renderLoading?: () => JSX.Element | null;
  redirect?: boolean | string;
}) => {
  const { data: role, loading: userRoleLoading } = useUserRole();
  const sectionEnabled = role?.roleDefinition.sectionEnabled;

  if (userRoleLoading || !sectionEnabled) {
    return renderLoading();
  }

  if (!checkHasPermission(sectionEnabled, permissions)) {
    // Do not redirect if no sections enabled /!\ loop...
    if (!redirect || !sectionEnabled.length) {
      return null;
    }
    return (
      <Redirect to={typeof redirect === "string" ? redirect : "information"} noThrow />
    );
  }

  return <>{children}</>;
};
