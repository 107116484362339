import {
  LittersAddHeader,
  LittersAddSubmit,
  LittersChoiceCards,
  Link,
  colors,
  IconArrowL10,
  Form,
  PageLoading,
  ColFormWrapper,
  LittersAddHeaderProps,
  WarningBox,
} from "@royalcanin-be-partner-portal/ui-kit";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "react-styled-flexboxgrid";
import { object, boolean } from "yup";
import {
  useCurrentPartnerDetails,
  usePartnerFromPuppyKitten,
} from "../lib/hooks/usePartnerDetails";
import { WarningNoHKNumber } from "./WarningNoHKNumber";
import { useUserRole } from "../lib/hooks/useUserRole";

export interface IFormLitterActions {
  pack: boolean;
  litter: boolean;
}

export const FormLitterActions = ({
  onSubmit,
  onCancel,
  initialValues,
  littersAddHeaderProps,
}: {
  onSubmit: (values: IFormLitterActions) => void;
  onCancel: () => void;
  initialValues?: Partial<IFormLitterActions>;
  littersAddHeaderProps?: LittersAddHeaderProps;
}) => {
  const { data: partner, loading } = useCurrentPartnerDetails();
  const {
    data: partnerPuppyKitten,
    loading: partnerPuppyKittenLoading,
  } = usePartnerFromPuppyKitten({ extId: partner?.extId || "" });
  const { data: userRoleData, loading: userRoleLoading } = useUserRole();

  if (loading || !partner || partnerPuppyKittenLoading || userRoleLoading) {
    return <PageLoading />;
  }

  const restrictionPartnergroup =
    userRoleData?.roleDefinition.litterKitsRestrictionPartnergroup || [];
  const isPackAutorized = () => {
    const groups = partner.partnerGroups || [];
    for (const group of groups) {
      if (restrictionPartnergroup.includes(group)) {
        return false;
      }
    }
    return true;
  };

  return (
    <Form<IFormLitterActions>
      initialValues={{
        pack: isPackAutorized(),
        litter: false,
        ...initialValues,
      }}
      schema={object().shape({
        pack: boolean().required(),
        litter: boolean(),
      })}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row center="xs">
              <ColFormWrapper xs={12} sm={12} md={10} lg={8}>
                <LittersAddHeader
                  {...littersAddHeaderProps}
                  backLink={
                    <Link
                      href="/litters"
                      borderless
                      prefix={
                        <IconArrowL10
                          width={10}
                          height={19}
                          fill={colors.primary}
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      <FormattedMessage
                        id="button.label.back"
                        defaultMessage="Retour"
                      />
                    </Link>
                  }
                  submitButton={false}
                >
                  <FormattedMessage
                    id="website.litters.add.title"
                    defaultMessage="Que souhaitez-vous faire ?"
                  />
                </LittersAddHeader>
                <WarningNoHKNumber />
                {!isPackAutorized() && (
                  <WarningBox wrapper>
                    <div>
                      <FormattedMessage
                        id="litters.warning.kit_blocked"
                        defaultMessage="La déclaration de portée n'est pas disponible pour votre élevage. Veuillez contacter votre délégué commercial."
                      />
                    </div>
                  </WarningBox>
                )}
              </ColFormWrapper>
              <ColFormWrapper xs={12} sm={12} md={10} lg={8}>
                <LittersChoiceCards
                  disabledAd={
                    !partner.isConv ||
                    (!partnerPuppyKitten?.isHKNumberValid && !partner.HKNumber) ||
                    !isPackAutorized()
                  }
                />
                <LittersAddSubmit disabled={!values.pack} />
              </ColFormWrapper>
            </Row>
          </form>
        );
      }}
    />
  );
};
