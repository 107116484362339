import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, WarningBox } from "@royalcanin-be-partner-portal/ui-kit";
import {
  useCurrentPartnerDetails,
  usePartnerFromPuppyKitten,
} from "../lib/hooks/usePartnerDetails";

export const WarningNoHKNumber = () => {
  const { data: partner, loading } = useCurrentPartnerDetails();
  const {
    data: partnerPuppyKitten,
    loading: partnerPuppyKittenLoading,
  } = usePartnerFromPuppyKitten({ extId: partner?.extId || "" });

  if (
    loading ||
    !partner ||
    partnerPuppyKittenLoading ||
    !!partnerPuppyKitten?.isHKNumberValid ||
    !!partner?.HKNumber
  ) {
    return null;
  }

  return (
    <WarningBox wrapper>
      <div>
        <FormattedMessage
          id="litters.warning.missing_hk_number"
          defaultMessage="Sans un numéro HK complété, vos portées ne peuvent pas être publiée sur les sites Royal Canin. Veuillez compléter votre numero HK"
        />{" "}
        <Link href="/information#breedersinfo">
          <FormattedMessage
            id="website.nav.informations"
            defaultMessage="Informations"
          />
        </Link>
      </div>
    </WarningBox>
  );
};
