import React, { useState } from "react";
import { IFormLitterBaseInfoValues } from "./FormLitterBaseInfo";
import { IFormLitterPackValues, FormLitterPack } from "./FormLitterPack";
import { FormLitterAdStepAvailable } from "./FormLitterAdStepAvailable";
import { FormLitterAdStepAd } from "./FormLitterAdStepAd";
import { FormLitterDetails } from "./FormLitterDetails";
import { IFormLitterDetailsValues } from "./FormLitterDetailsInputs";
import { LittersAddHeaderProps } from "@royalcanin-be-partner-portal/ui-kit";

interface IFormLitterAdAvailableValues {
  dateAvailable: string;
  availableMale: number;
  availableFemale: number;
}

type IFormLitterAdValues = IFormLitterBaseInfoValues &
  IFormLitterPackValues &
  IFormLitterAdAvailableValues &
  IFormLitterDetailsValues;

type ISteps = "available" | "ad" | "pack" | "details";

export const FormLitterAd = ({
  pack,
  onSubmit,
  onCancel,
  initialValues,
  littersAddHeaderProps
}: {
  pack?: boolean;
  onSubmit: (values: any) => Promise<void>;
  readonlyTotal?: boolean;
  onCancel: () => void;
  initialValues?: Partial<
    IFormLitterBaseInfoValues & IFormLitterPackValues & IFormLitterDetailsValues
  >;
  littersAddHeaderProps?: LittersAddHeaderProps
}) => {
  const [step, setStep] = useState<ISteps>("available");
  const [values, setValues] = useState<Partial<IFormLitterAdValues>>(
    initialValues || {},
  );
  return (
    <>
      {step === "available" && (
        <FormLitterAdStepAvailable
          onSubmit={(v) => {
            setValues({ ...values, ...v });
            setStep("ad");
          }}
          onCancel={() => {
            onCancel();
          }}
          initialValues={initialValues}
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}

      {step === "ad" && (
        <FormLitterAdStepAd
          onSubmit={async (v) => {
            setValues({ ...values, ...v });
            setStep("details");
          }}
          onCancel={() => {
            setStep("available");
          }}
          initialValues={values}
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}

      {step === "details" && (
        <FormLitterDetails
          onSubmit={async ({ otherBreeding, ...v }) => {
            const data = {
              ...values,
              ...v,
              pedigreeFCI: v.pureBreed ? v.pedigreeFCI : false,
              idFather: v.pureBreed ? v.idFather || null : null,
              idMother: v.idMother || null,
              originCountry: otherBreeding ? v.originCountry || null : null,
              originBreeder: otherBreeding ? v.originBreeder || null : null,
            };
            setValues(data);
            if (pack) {
              setStep("pack");
            } else {
              await onSubmit(data);
            }
          }}
          onCancel={() => {
            setStep("ad");
          }}
          initialValues={values}
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}

      {step === "pack" && (
        <FormLitterPack
          readonlyTotal
          initialValues={{
            ...values,
            male: values.male || 0,
            female: values.female || 0,
            genderUnspecifiedCount: values.genderUnspecifiedCount || 0
          }}
          onSubmit={async (v) => {
            await onSubmit({
              ...values,
              ...v,
            });
          }}
          onCancel={() => {
            setStep("details");
          }}
          littersAddHeaderProps={littersAddHeaderProps}
        />
      )}
    </>
  );
};
