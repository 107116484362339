import React from "react";
import {
  Form,
  LittersAddHeader,
  Link,
  IconArrowL10,
  colors,
  LittersAddHeaderProps,
} from "@royalcanin-be-partner-portal/ui-kit";
import {
  IFormLitterBaseInfoValues,
  messages as messagesBaseInfo,
} from "./FormLitterBaseInfo";
import { IFormLitterPackValues } from "./FormLitterPack";
import { object, string, number } from "yup";
import { Row, Col } from "react-styled-flexboxgrid";
import { FormattedMessage, useIntl } from "react-intl";
import {
  FormLitterAvailableInputs,
  IFormLitterAdAvailableValues,
  getInitialValues,
  getValidationDateAvailableMinDate,
  getValidationDateAvailableMaxDate,
} from "./FormLitterAvailableInputs";
import { startOfDay } from "date-fns/esm";

export const FormLitterAdStepAvailable = ({
  onSubmit,
  onCancel,
  initialValues,
  littersAddHeaderProps
}: {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialValues?: Partial<IFormLitterBaseInfoValues & IFormLitterPackValues>;
  littersAddHeaderProps?: LittersAddHeaderProps
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form<IFormLitterAdAvailableValues>
      initialValues={{
        ...getInitialValues(initialValues),
      }}
      schema={object().shape({
        dateAvailable: string()
          .test(
            "dateAvailableInterval",
            formatMessage(messagesBaseInfo.formErrorInvalidDateAvailable),
            value => {
              const val = startOfDay(new Date(value));
              const minDate = startOfDay(
                initialValues?.dob ? getValidationDateAvailableMinDate(initialValues?.dob) : new Date(),
              );
              const maxDate = startOfDay(
                initialValues?.dob ? getValidationDateAvailableMaxDate(initialValues?.dob) : new Date(),
              );
              return val >= minDate && val <= maxDate;
            },
          )
          .required(),
        availableMale: number()
          .typeError(formatMessage(messagesBaseInfo.formErrorNumber))
          .integer(formatMessage(messagesBaseInfo.formErrorNumber))
          .min(0)
          .max(
            (initialValues?.male || 0) +
              (initialValues?.genderUnspecifiedCount || 0) || 10,
          )
          .required(),
        availableFemale: number()
          .typeError(formatMessage(messagesBaseInfo.formErrorNumber))
          .integer(formatMessage(messagesBaseInfo.formErrorNumber))
          .min(0)
          .max(
            (initialValues?.female || 0) +
              (initialValues?.genderUnspecifiedCount || 0) || 10,
          )
          .required(),
      })}
      onSubmit={values => {
        onSubmit(values);
      }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row center="xs">
              <Col xs={12} sm={12} md={8}>
                <LittersAddHeader
                  {...littersAddHeaderProps}
                  backLink={
                    <Link
                      href="/litters"
                      borderless
                      prefix={
                        <IconArrowL10
                          width={10}
                          height={19}
                          fill={colors.primary}
                        />
                      }
                      onClick={e => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      <FormattedMessage
                        id="button.label.back"
                        defaultMessage="Retour"
                      />
                    </Link>
                  }
                  submitLabel={
                    <FormattedMessage
                      id="button.label.continue"
                      defaultMessage="Continuer"
                    />
                  }
                >
                  <FormattedMessage
                    id="website.litters.add.litter.title"
                    defaultMessage="Déclarer une portée"
                  />
                </LittersAddHeader>
              </Col>
              <Col xs={12} sm={12} md={8}>
                <FormLitterAvailableInputs values={initialValues} />
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};
