import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { useLocale } from "../locale";
import { Locale } from "../../../../../__generated__/globalTypes";
import { Services, ServicesVariables } from "./__generated__/Services";
import { GetService, GetServiceVariables } from "./__generated__/GetService";

const ServiceFragment = gql`
  fragment ServiceFragment on Service {
    id
    label
    icon
    url
    accessToken
  }
`;

const ServicesQuery = gql`
  query Services($token: String!, $locale: Locale!) {
    services(token: $token, locale: $locale) {
      result {
        ...ServiceFragment
      }
      error {
        message
        code
      }
    }
  }
  ${ServiceFragment}
`;

export const useServices = () => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Services, ServicesVariables>(
    ServicesQuery,
    {
      skip: !token,
      variables: {
        token,
        locale: Locale[locale],
      },
    },
  );

  return {
    ...res,
    data,
    items: data?.services.result || [],
  };
};

const ServiceMutation = gql`
  mutation GetService($token: String!, $locale: Locale!, $id: String!) {
    service(token: $token, locale: $locale, id: $id) {
      result {
        ...ServiceFragment
        urlProtected(token: $token)
      }
      error {
        message
        code
      }
    }
  }
  ${ServiceFragment}
`;

export const useService = () => {
  const token = useUserToken();
  const locale = useLocale();
  const [getService, result] = useMutation<GetService, GetServiceVariables>(
    ServiceMutation,
  );

  return {
    ...result,
    error:
      result.error ||
      (result.data && result.data.service && result.data.service.error),
    getService: async (id: string) => {
      const { data, errors, ...res } = await getService({
        variables: {
          token,
          locale: locale as Locale,
          id,
        },
      });
      return {
        ...res,
        data,
        errors,
        error: errors && errors[0],
        item: data?.service.result
      };
    },
  };
};
