import React from "react";
import {
  Form,
  LittersAddHeader,
  Link,
  IconArrowL10,
  colors,
  ColFormWrapper,
  LittersAddHeaderProps,
} from "@royalcanin-be-partner-portal/ui-kit";
import { object } from "yup";
import { Row } from "react-styled-flexboxgrid";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IFormLitterDetailsValues,
  FormLitterDetailsInputs,
  getInitialValues,
  getValidation,
} from "./FormLitterDetailsInputs";

export const FormLitterDetails = ({
  onSubmit,
  onCancel,
  initialValues,
  littersAddHeaderProps,
}: {
  onSubmit: (values: IFormLitterDetailsValues) => Promise<void>;
  onCancel: () => void;
  initialValues?: Partial<IFormLitterDetailsValues>;
  littersAddHeaderProps?: LittersAddHeaderProps;
}) => {
  const intl = useIntl();
  return (
    <Form<IFormLitterDetailsValues>
      initialValues={{
        ...getInitialValues(initialValues),
      }}
      schema={object().shape({
        ...getValidation({ intl }),
      })}
      onSubmit={async ({ otherBreeding, ...values }) => {
        await onSubmit(values);
      }}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row center="xs">
              <ColFormWrapper xs={12} sm={12} md={8}>
                <LittersAddHeader
                  {...littersAddHeaderProps}
                  backLink={
                    <Link
                      href="/litters"
                      borderless
                      prefix={
                        <IconArrowL10
                          width={10}
                          height={20}
                          fill={colors.primary}
                        />
                      }
                      onClick={e => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      <FormattedMessage
                        id="button.label.back"
                        defaultMessage="Retour"
                      />
                    </Link>
                  }
                  submitLabel={
                    <FormattedMessage
                      id="button.label.continue"
                      defaultMessage="Continuer"
                    />
                  }
                  submitDisabled={submitting}
                >
                  <FormattedMessage
                    id="website.litters.add.litter.title"
                    defaultMessage="Déclarer une portée"
                  />
                </LittersAddHeader>
              </ColFormWrapper>
              <ColFormWrapper xs={12} sm={12} md={8}>
                <FormLitterDetailsInputs values={values} />
              </ColFormWrapper>
            </Row>
          </form>
        );
      }}
    />
  );
};
