import { FORM_ERROR } from "final-form";
import React from "react";
import {
  IErrorResult,
  Form,
  Alert,
  useInputProps,
  Input,
  Fieldset,
  FormTitle,
  Loader,
  passwordManagerFormMessages,
} from "@royalcanin-be-partner-portal/ui-kit";
import { useIntl, defineMessages, IntlShape } from "react-intl";
import { Row, Col } from "react-styled-flexboxgrid";
import { ModalFooter } from "@royalcanin-be-partner-portal/ui-kit/src/ModalFooter";
import {
  useUser,
  useMetadataAccountPasswordPolicy,
} from "@igloo-be-omnipartners/hooks";
import * as yup from "yup";
import { useUserUpdatePassword } from "../lib/hooks/useUserUpdatePassword";

interface IFormMyCredentialsValues {
  email: string;
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

const messages = defineMessages({
  email: {
    id: "form.label.email",
    defaultMessage: "Adresse email",
  },
  oldPassword: {
    id: "form.label.oldPassword",
    defaultMessage: "Ancien mot de passe",
  },
  errorPassword: {
    id: "updatePassword.error.badPassword",
    defaultMessage: "Mot de passe incorrect",
  },
});

const formatError = (
  error: { code: string; message: string },
  { formatMessage }: { formatMessage: IntlShape["formatMessage"] },
) => {
  if (
    error.code === "OP/OPStatusError/3" ||
    error.code === "OP/OPStatusError/5" ||
    error.code === "OP/OPStatusError/17"
  ) {
    return formatMessage(messages.errorPassword);
  } else if (error.code === "E_NOT_AUTHORIZED") {
    return `${error.message}`;
  } else {
    return `${error.code} - ${error.message}`;
  }
};

export const FormMyCredentials = ({
  onClose = () => {},
  onSubmit = async () => {},
}: {
  onClose: () => void;
  onSubmit: (values: any) => Promise<IErrorResult | null | void>;
}) => {
  const { user, loading: userLoading } = useUser();
  const {
    passwordPolicy: passwordPolicyData,
    loading: passwordPolicyLoading,
  } = useMetadataAccountPasswordPolicy();
  const passwordPolicy = passwordPolicyData?.alt_password_format;
  const { formatMessage } = useIntl();
  const { userUpdatePassword, loading } = useUserUpdatePassword();

  if (userLoading || !user || passwordPolicyLoading) {
    return <Loader />;
  }

  const onSubmitForm = async (
    values: Pick<IFormMyCredentialsValues, "oldPassword" | "password">,
  ) => {
    const { error } = await userUpdatePassword(values);
    if (error) {
      return error;
    }
    onSubmit(values);
    return;
  };

  return (
    <Form<IFormMyCredentialsValues>
      loading={loading}
      initialValues={{
        email: user.owner.email || "",
        oldPassword: "",
        password: "",
        passwordConfirm: "",
      }}
      fieldLabels={{
        email: formatMessage(messages.email),
        oldPassword: formatMessage(messages.oldPassword),
        password: formatMessage(passwordManagerFormMessages.password),
        passwordConfirm: formatMessage(
          passwordManagerFormMessages.passwordConfirm,
        ),
      }}
      schema={yup.object().shape({
        oldPassword: yup.string().required(),
        password: passwordPolicy
          ? yup
              .string()
              .matches(
                new RegExp(passwordPolicy),
                formatMessage(passwordManagerFormMessages.passwordErrorRgx),
              )
              .required()
          : yup.string().min(6).required(),
        passwordConfirm: yup
          .string()
          .oneOf(
            [yup.ref("password"), null],
            formatMessage(passwordManagerFormMessages.confirmPasswordMatch),
          ),
      })}
      onSubmit={async ({ oldPassword, password }) => {
        const error = await onSubmitForm({ oldPassword, password });
        if (error) {
          return {
            [FORM_ERROR]: formatError(error, { formatMessage }),
          };
        }

        onClose();
        return;
      }}
      render={({ handleSubmit, submitError, submitting, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}

            <Input fullWidth {...useInputProps({ name: "email" })} disabled />

            <FormTitle spacing>Modifier mon mot de passe</FormTitle>
            <Row>
              <Col xs={12}>
                <Fieldset responsive>
                  <Input
                    fullWidth
                    type="password"
                    {...useInputProps({ name: "oldPassword" })}
                  />
                </Fieldset>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Fieldset responsive offset={30}>
                  <Input
                    fullWidth
                    type="password"
                    {...useInputProps({ name: "password" })}
                  />
                </Fieldset>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Fieldset responsive offset={30}>
                  <Input
                    fullWidth
                    type="password"
                    {...useInputProps({ name: "passwordConfirm" })}
                  />
                </Fieldset>
              </Col>
            </Row>

            <ModalFooter
              onClose={onClose}
              buttonType="submit"
              disabled={submitting || pristine || loading}
            />
          </form>
        );
      }}
    />
  );
};
