import React, { useEffect } from "react";
import {
  Card,
  Dropdown,
  IDropdownItem,
  KitsCounter,
  ShippingAddress,
  useInputProps,
} from "@royalcanin-be-partner-portal/ui-kit";
import { Row, Col } from "react-styled-flexboxgrid";
import { InputPetType } from "./FormLitterInputs";
import { useLitterUnivers, useLitterBreeds } from "../lib/hooks/useLittersMetadata";
import { useCurrentPartnerDetails } from "../lib/hooks/usePartnerDetails";
import { useMetadataCountries } from "@igloo-be-omnipartners/hooks";
import { useLocale } from "../lib/locale";
import { useLitterKits } from "../lib/hooks/useLitterKits";
import { uniqBy } from "lodash";
import { navigate } from "gatsby"

export const LittersFormPackInputs = ({
  readonlyTotal,
}: {
  readonlyTotal?: boolean;
}) => {
  const locale = useLocale();
  const inputPetTypeProps = useInputProps({ name: "type" });
  const inputBreedProps = useInputProps({ name: "breedId" });
  const inputPackRangeProps = useInputProps({ name: "packRange" });
  const { litterKits: baseLitterKits } = useLitterKits();
  const litterKits = baseLitterKits.filter(
    (l) =>
      (inputPetTypeProps.value &&
        l.petType.toLowerCase() === inputPetTypeProps.value.toLowerCase()),
  );
  const { items: breeds } = useLitterBreeds({
    type: inputPetTypeProps.value,
  });
  const { items: univers } = useLitterUnivers({
    type: inputPetTypeProps.value,
  });
  const { data: partner, loading } = useCurrentPartnerDetails();
  const { items: countries } = useMetadataCountries({
    lang: locale,
    skip: loading,
  });

  const breed = breeds.find((b) => b.id === inputBreedProps.value);

  const breedSuggestions = litterKits
    .map((kit) => {
      if (
        kit.breeds &&
        breed &&
        kit.breeds.includes(breed.delocalizedId)
      ) {
        return {
          label: kit.label,
          value: kit.reference,
        };
      }
      return;
    })
    .filter(Boolean) as IDropdownItem<string>[];

  const inferedUniverseId =
    inputPetTypeProps.value === "CAT" && univers.length
      ? univers[0].delocalizedId
      : univers.find((universe) => universe.id === breed?.univers?.id)
          ?.delocalizedId;
  const universeSuggestions = litterKits
    .map((kit) => {
      if (
        kit.universes &&
        inferedUniverseId &&
        kit.universes.includes(inferedUniverseId)
      ) {
        return {
          label: kit.label,
          value: kit.reference,
        };
      }
      return;
    })
    .filter(Boolean) as IDropdownItem<string>[];

  const kitByUniverse = litterKits
    .filter((kit) => kit.mode === "universe")
    .filter(
      (kit) =>
        kit.availableUniverses &&
        inferedUniverseId &&
        kit.availableUniverses.includes(inferedUniverseId),
    )
    .map((kit) => ({
      label: kit.label,
      value: kit.reference,
    }));

  const kitByBreed = litterKits
    .filter((kit) => kit.mode === "breed")
    .map((kit) => ({
      label: kit.label,
      value: kit.reference,
    }));

  const kitSelectorChoices = [
    {
      label: "Suggéré pour vous",
      value: "suggestion",
      items: uniqBy([...breedSuggestions, ...universeSuggestions], "value"),
    },
    {
      label: "Par taille",
      value: "size",
      items: kitByUniverse,
    },
    {
      label: "Par race",
      value: "breeds",
      items: kitByBreed,
    },
  ];

  // Auto select first suggested kit
  const autoSelectKit =
    kitSelectorChoices[0].items[0] && kitSelectorChoices[0].items[0].value;
  useEffect(() => {
    if (!inputPackRangeProps.value && autoSelectKit) {
      inputPackRangeProps.onChange(autoSelectKit);
    }
  }, [autoSelectKit, inputPackRangeProps.value]);

  const currentPackRange = litterKits.find((k) => k.reference === inputPackRangeProps.value);

  return (
    <Card spacing="md">
      <Row>
        <Col xs={6}>
          <InputPetType {...inputPetTypeProps} disabled />
        </Col>
        <Col xs={6}>
          <Dropdown
            {...useInputProps({ name: "packRange" })}
            fullWidth
            items={kitSelectorChoices}
            disabled={loading}
          />
        </Col>
      </Row>
      <KitsCounter
        {...useInputProps({ name: "numberOfPack" })}
        title={currentPackRange?.label || ""}
        readonly={readonlyTotal}
        univers={univers}
      />
      <ShippingAddress
        loading={loading}
        partner={partner}
        countries={countries}
        onClickEditAddress={() => {
          navigate("/information");
        }}
      />
    </Card>
  );
};
