import { useUserContext, useUserLogout } from "@igloo-be-omnipartners/hooks";
import {
  Layout as DefaultLayout,
  colors,
  IconFolder20,
  IconCoupon20,
  IconLitter20,
  IconFood20,
  IconDeal20,
  icons,
  IMenuItem,
  IconGear20,
  IconCup20,
} from "@royalcanin-be-partner-portal/ui-kit";
import React from "react";
import { useUserCheckRemote } from "../lib/hooks/useUserCheckRemote";
import { useUserRoles } from "../lib/hooks/useUserRoles";
import { useLocale, useSetLocale } from "../lib/locale";
import { FormattedMessage } from "react-intl";
import { useServices, useService } from "../lib/hooks/useServices";
import { useUserRole } from "../lib/hooks/useUserRole";
import { checkHasPermission } from "./CheckPermission";
import { isAccessTokenLoginLoading } from "../lib/AccessTokenLogin";
import { FormMyCredentials } from "./FormMyCredentials";
import { navigate } from "gatsby";

const navLinks: (IMenuItem & { permissions: string | string[] })[] = [
  {
    icon: <IconGear20 width={20} height={20} fill={colors.darkDk} />,
    href: "/information",
    label: (
      <FormattedMessage
        id="website.nav.informations"
        defaultMessage="Informations"
      />
    ),
    permissions: "information",
  },
  {
    icon: <IconFolder20 width={20} height={16} fill={colors.darkDk} />,
    href: "/resources",
    label: (
      <FormattedMessage
        id="website.nav.resources"
        defaultMessage="Ressources"
      />
    ),
    permissions: "resources",
  },
  {
    icon: <IconCoupon20 width={20} height={20} fill={colors.darkDk} />,
    href: "/coupons",
    hrefChildren: ["/coupons/list"],
    label: (
      <FormattedMessage id="website.nav.coupons" defaultMessage="Coupons" />
    ),
    permissions: "coupons",
  },
  {
    icon: <IconLitter20 width={20} height={20} fill={colors.darkDk} />,
    href: "/litters",
    label: (
      <FormattedMessage id="website.nav.litters" defaultMessage="Portées" />
    ),
    permissions: "litters",
  },
  {
    icon: <IconFood20 width={20} height={25} fill={colors.darkDk} />,
    href: "/products",
    label: (
      <FormattedMessage id="website.nav.products" defaultMessage="Produits" />
    ),
    permissions: "products",
  },
  {
    icon: <IconDeal20 width={20} height={20} fill={colors.darkDk} />,
    href: "/promos",
    label: <FormattedMessage id="website.nav.promos" defaultMessage="Promos" />,
    permissions: "promos",
  },
  {
    icon: <IconCup20 width={20} height={20} fill={colors.darkDk} />,
    href: "/loyalty",
    label: (
      <FormattedMessage id="website.nav.loyalty" defaultMessage="Loyalty" />
    ),
    permissions: "loyalty",
  },
];

export const footerLinks = [
  {
    label: (
      <FormattedMessage
        id="website.footerLink.terms"
        defaultMessage="Conditions d'utilisation"
      />
    ),
    link: {
      fr: "https://www.mars.com/legal-france",
      nl: "https://www.mars.com/legal-netherlands",
    },
  },
  {
    label: (
      <FormattedMessage
        id="website.footerLink.cookies"
        defaultMessage="Cookies"
      />
    ),
    link: {
      fr: "https://www.mars.com/cookies-france",
      nl: "https://www.mars.com/cookies-netherlands",
    },
  },
  {
    label: (
      <FormattedMessage
        id="website.footerLink.privacy"
        defaultMessage="Protection de la vie privée"
      />
    ),
    link: {
      fr: "https://www.mars.com/privacy-policy-french",
      nl: "https://www.mars.com/privacy-policy-netherlands",
    },
  },
  {
    label: (
      <FormattedMessage
        id="website.footerLink.contact"
        defaultMessage="Contactez nous"
      />
    ),
    link: {
      fr: "https://www.royalcanin.com/be/contact-us",
      nl: "https://www.royalcanin.com/be/nl_be/contact-us",
    },
  },
  {
    label: (
      <FormattedMessage
        id="website.footerLink.accessibility"
        defaultMessage="Accessibilité"
      />
    ),
    link: {
      fr: "https://www.mars.com/accessibility-french",
      nl: "https://www.mars.com/accessibility-dutch",
    },
  },
];

export const Layout = (props: any) => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const logout = useUserLogout();
  const { data: userRoles, loading: userRolesLoading } = useUserRoles();
  const { setToken } = useUserContext();
  const { items: services } = useServices();
  const { getService, loading: getServiceLoading } = useService();
  const { data: role } = useUserRole();
  const tokenLoginLoading = isAccessTokenLoginLoading();

  useUserCheckRemote({
    onNotLogged: () => {
      if (!tokenLoginLoading) {
        logout();
        navigate("/");
      }
    },
    onError: () => {
      logout();
      navigate("/");
    },
  });

  const onPartnerChange = (token: string) => {
    setToken(token);
  };

  return (
    <DefaultLayout
      {...props}
      locale={locale}
      onLocaleChange={setLocale}
      userRoles={(userRoles || []).map((iRole) => ({
        ...iRole,
        partner: iRole.partnerRelation.partner,
      }))}
      userRolesLoading={userRolesLoading}
      onPartnerChange={onPartnerChange}
      navLinks={navLinks.filter((item) =>
        checkHasPermission(
          role?.roleDefinition.sectionEnabled || [],
          item.permissions,
        ),
      )}
      footerLinks={footerLinks.map((footerLink) => ({
        ...footerLink,
        href: footerLink.link[locale],
      }))}
      services={services.map((service) => ({
        ...service,
        icon: React.createElement(icons[service.icon as keyof typeof icons], {
          width: 16,
          height: 16,
          fill: colors.primary,
        }),
        href: service.url || "",
        onClick: async (e) => {
          if (!e || !service.accessToken || getServiceLoading) {
            return;
          }
          e.preventDefault();
          const { item } = await getService(service.id);
          if (item) {
            window.open(item.urlProtected);
          }
        },
      }))}
      onClickLogout={() => {
        logout();
      }}
      FormMyCredentialsComponent={FormMyCredentials}
    />
  );
};
