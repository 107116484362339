import React from "react";
import {
  colors,
  Link,
  LittersAddHeader,
  Form,
  IconArrowL10,
  ColFormWrapper,
  LittersAddHeaderProps,
} from "@royalcanin-be-partner-portal/ui-kit";
import { Row } from "react-styled-flexboxgrid";
import { object, string } from "yup";
import { FormattedMessage } from "react-intl";
import { LittersFormPackInputs } from "./FormLitterPackInputs";

export interface IFormLitterPackValues {
  type: string;
  packRange: string;
  numberOfPack: number;
}

export const FormLitterPack = ({
  onSubmit,
  readonlyTotal,
  onCancel,
  initialValues,
  littersAddHeaderProps
}: {
  onSubmit: (values: IFormLitterPackValues) => Promise<void>;
  readonlyTotal?: boolean;
  onCancel: () => void;
  initialValues?: Partial<IFormLitterPackValues> & {
    male: number;
    female: number;
    genderUnspecifiedCount: number;
  };
  littersAddHeaderProps?: LittersAddHeaderProps
}) => {
  return (
    <Form<IFormLitterPackValues>
      initialValues={{
        type: "",
        packRange: "",
        numberOfPack:
          parseInt(`${initialValues?.male || "0"}`, 10) +
          parseInt(`${initialValues?.female || "0"}`, 10) +
          parseInt(`${initialValues?.genderUnspecifiedCount || "0"}`, 10),
        ...initialValues,
      }}
      schema={object().shape({
        type: string().required(),
        packRange: string().required(),
      })}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      fieldLabels={{
        packRange: "Pack",
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row center="xs">
              <ColFormWrapper xs={8}>
                <LittersAddHeader
                  {...littersAddHeaderProps}
                  backLink={
                    <Link
                      href="/litters"
                      borderless
                      prefix={
                        <IconArrowL10
                          width={10}
                          height={20}
                          fill={colors.primary}
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      <FormattedMessage
                        id="button.label.back"
                        defaultMessage="Retour"
                      />
                    </Link>
                  }
                  submitLabel={
                    <FormattedMessage
                      id="button.label.confirm"
                      defaultMessage="Confirmer"
                    />
                  }
                  submitDisabled={submitting}
                >
                  <FormattedMessage
                    id="website.litters.add.kits.title"
                    defaultMessage="Commander des colis"
                  />
                </LittersAddHeader>
              </ColFormWrapper>
              <ColFormWrapper xs={8}>
                <LittersFormPackInputs readonlyTotal={readonlyTotal} />
              </ColFormWrapper>
            </Row>
          </form>
        );
      }}
    />
  );
};
